import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import '../Contact.css'; // קובץ CSS לעיצוב
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
  const location = useLocation();
 
  const telepon = 'tel:+972509212108'
  const email = 'mailto:hilahezi86@gmail.com';
  const whatsapp = 'https://wa.me/972509212108?text=שלום%20הילה%2C%20אני%20מעוניין%2Fת%20בקבלת%20פרטים%20על%20הפעלה...'; // הכנס את מספר הטלפון שלך בפורמט בינלאומי


  if (location.pathname === '/') {
    return null;
  }

  return (
    <header>
 <div className="contact-icons">
   <a href={telepon} className="contact-icon">
   <FontAwesomeIcon icon={faPhone} size="2x"/>
     {/* <img src="/images/tel1.png" alt="טל" class="tele-img" /> */}
    </a>

    <a href={email} className="contact-icon">
      <FontAwesomeIcon icon={faEnvelope} size="2x" />
    </a>
    <a href={whatsapp} target="_blank" rel="noopener noreferrer" className="contact-icon">
      <FontAwesomeIcon icon={faWhatsapp} size="2x" />
    </a>
    

  </div>
      <nav>
        <ul className="nav-links">
          <li className="pic">
            <Link to="/">
              <img src="/images/logoo.png" alt="לוגו" />
            </Link>
          </li>
          <li className="pic">
            <Link to="/about">
              <img src="/images/about-icon.png" alt="מי אני" />
            </Link>
          </li>
          <li className="pic">
            <Link to="/BirthdayActivities3to6">
              <img src="/images/HB.png" alt="ימי הולדת 3-6" />
            </Link>
          </li>
          <li className="pic">
            <Link to="/BritotEvents">
              <img src="/images/brit.png" alt="בריתות ואירועים" />
            </Link>
          </li>
          <li className="pic">
            <Link to="/BirthdayParties7to10">
              <img src="/images/bd-710.png" alt="ימי הולדת 7-10" />
            </Link>
          </li>
          <li className="pic">
            <Link to="/makeup">
              <img src="/images/ipor.png" alt="איפור פנים" />
            </Link>
          </li>
          <li className="pic">
            <Link to="/shapes-balloons">
              <img src="/images/balon-icon.png" alt="בלוני צורות" />
            </Link>
          </li>
          <li className="pic">
            <Link to="/gallery">
              <img src="/images/images-icon.png" alt="תמונות" />
            </Link>
          </li>
          <li className="pic">
            <Link to="/Recommendations">
              <img src="/images/aml.png" alt="המלצות" />
            </Link>
          </li>
          <li className="pic">
            <Link to="/PastPerformances">
              <img src="/images/show-past.png" alt="מופעים קודמים" />
            </Link>
          </li>
        </ul>
      </nav>


    </header>
  );
};

export default Header;
