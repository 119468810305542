import React, { useState, useRef } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom'; // Import ה-Link מ-React Router


Modal.setAppElement('#root');

const images = [
  "/brit/1.jpg",
  "/brit/2.jpg",
  "/brit/3.jpg",
  "/brit/4.jpg",
  "/brit/6.jpg",
  "/brit/7.jpg",
  "/brit/8.jpg",
  "/brit/9.jpg",
  "/brit/10.jpg",
  "/brit/11.jpg",
  "/brit/12.jpg",
  "/brit/13.jpg",
  "/brit/14.jpg",
  "/brit/15.jpg",
];


const BritotEvents = () => {
  
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const toggleSlideBar = () => {
    setIsOpen(!isOpen);
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const openModal = (index) => {
    setSelectedImageIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImageIndex(null);
  };

  const goToPreviousImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const goToNextImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  const pages = [
    { name: "עמוד הבית", url: "/" },
    { name: "מי אני", url: "/about" },
    { name: "ימי הולדת 3-6", url: "/BirthdayActivities3to6" },
    { name: "בריתות ואירועים", url: "/BritotEvents" },
    { name: (
      <>
        ימי הולדת בנות
        <br />
        7-10
      </>
    ), 
      url: "/BirthdayParties7to10" },    { name: "איפור פנים לבנות ובנים", url: "/makeup" },
    { name: "בלוני צורות", url: "/shapes-balloons" },
    { name: "גלריית תמונות", url: "/gallery" },
    { name: "הורים ממליצים", url: "/Recommendations" },
    { name: "הופעות ואירועים מהעבר", url: "/PastPerformances" },
  ];


    return (
      <main onMouseDown={handleClickOutside}> 
          <div className="mobile-logo">
  <a href="/" className="home-link">
    <img src="/images/hila-logo.png" alt="לוגו בית" />
  </a>
    </div>
  <div className="mobile-logo1">
  <a href="tel:+972509212108">
    <img src="/images/tel1.png" alt="לוגו האתר" />
  </a>
</div>    
          <section>

          <div className="hamburger-menu">
          <button className="hamburger-button" onClick={toggleSlideBar}>
            ☰
          </button>
        </div>
        <div ref={menuRef} className={`side-menu ${isOpen ? 'open' : ''}`}>
          <button className="close-button" onClick={toggleSlideBar}>×</button>
          <ul>
            {pages.map((page, index) => (
              <li key={index}>
                <Link to={page.url} onClick={toggleSlideBar}>{page.name}</Link>
              </li>
            ))}
          </ul>
        </div>
            <h1>הפעלה בברית/ה או באירוע משפחתי</h1>
            <h2>נולד לכם בייבי קטנ/ה או יש לכם אירוע עם כל ילדי המשפחה - מזל טוב! בדיוק בשביל זה אני כאן.</h2>
            <p>הפעלת ילדים באירועים נועדה כדי לתת שקט וזמן איכות למבוגרים בלי לשכוח לשמח את הילדים ולתת להם חוויה כיפית, משמחת ונעימה.</p>
            <h2>אז מה בתפריט:</h2>
            <p><strong>אני עושה 4 אטרקציות שונות על מנת שיהיה מגוון ומעניין.</strong></p>
            <p> 
            &#10003; <span style={{ textDecoration: 'underline' }}> קבלת פנים</span> - דמות חמודה המברכת את הילדים בברכת שלום, הדמות לבחירתכם: דוב פנדה, פילה ורודה או דמות נסיכה.<br />
            &#10003; <span style={{ textDecoration: 'underline' }}> שולחן יצירת ברכות לחברים ולמשפחה</span> - שולחן עם איורים בדמויות מוכרות מעולם הילדים לצביעה וקישוט עם צבעים, טושים, מדבקות, דפים צבעוניים ועוד.<br/>
            <span className="indented">• המטרה, שהילדים יישארו נקיים ובו בזמן יתעסקו ביצירה כיפית וקלילה.</span><br/>
            &#10003; <span style={{ textDecoration: 'underline' }}> עמדת איפור פנים</span> - עמדה לכלל הילדים (והילדים בנפש), בעמדה אעשה איורי פנים וגוף כגון - קשת, עננים, פרפרים, נסיכות, עכבישים, בעלי חיים,נינג'ות, דרקונים ועוד.<br/>
            &#10003; <span style={{ textDecoration: 'underline' }}> חלוקת בלוני צורות</span> - לקראת סוף האירוע, אני מנפחת בלוני צורות בשלל צבעים ומכינה מהם צורות מגוונות לכל מי שחפץ, כגון: כתרים, חרבות, פרחים, חיות, צמידים, לבבות ועוד.
            </p>  
            <p><strong> • בנוסף לעמדות שאני מפעילה , <span style={{ textDecoration: 'underline' }}>אני פותחת פינת משחקים לכלל הילדים.</span></strong></p>
            <p>הפינה תהיה פתוחה כמעט כל האירוע, ותכלול בתוכה חישוקים, קלאס, פאזל גדול, מנהרה וכו' על מנת שלילדים תמיד תהיה עוד תעסוקה וגיוון.</p>
            <p>חשוב לזכור שאני מתאימה את עצמי לסוג האירוע ולילדים, הכל יכול מעט להשתנות לטובת הצלחת האירוע והנאת הילדים.</p>
            <p> • באירוע שכזה, אני נמצאת בין שעתיים וחצי עד שלוש שעות.</p>
            <p> • ישנה אפשרות להזמין אותי לחצי אירוע - ולבחור 2 אטרקציות מהרשימה.</p>
            <p><strong>שיהיה במזל טוב!</strong></p>

    
    <div className="gallery">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`תמונה ${index + 1}`}
              onClick={() => openModal(index)}
            />
          ))}
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className="Modal"
          overlayClassName="Overlay"
        >
          {selectedImageIndex !== null && (
            <div className="modal-content">
              <div className="modal-buttons">
                <button onClick={closeModal} className="Modal-close-button">
                <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              <img src={images[selectedImageIndex]} alt="תמונה מוגדלת" />
              <div className="modal-buttons">
                <button onClick={goToPreviousImage} className="modal-button">
                  <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <button onClick={goToNextImage} className="modal-button">
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </div>
            </div>
          )}
        </Modal>
          </section>
        </main>
      );
};

export default BritotEvents;
