// src/pages/Contact.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import '../Contact.css'; // הקובץ CSS לעיצוב

const Contact = () => {
  const phone = 'tel:+972509212108';
  const email = 'mailto:natihazi7@gmail.com';
  const whatsapp = 'https://wa.me/972509212108?text=שלום%2C%20אני%20מעוניין%20בקבלת%20פרטים%20נוספים.'; // הכנס את מספר הטלפון שלך בפורמט בינלאומי


  return (
    <main>
      <section>
        <div className="contact-icons">
          <a href={phone} className="contact-icon">
            <FontAwesomeIcon icon={faPhone} size="6x" />

          </a>
          <a href={email} className="contact-icon">
            <FontAwesomeIcon icon={faEnvelope} size="6x" />

          </a>
          <a href={whatsapp} className="contact-icon">
            <FontAwesomeIcon icon={faWhatsapp} size="6x" />

          </a>
        </div>
      </section>
    </main>
  );
};

export default Contact;
