import React, { useState, useRef } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import '../App.css'; // הוסף את קובץ ה-CSS הזה
import { Link } from 'react-router-dom'; // Import ה-Link מ-React Router


Modal.setAppElement('#root');

const images = [
  "/ipor/1.jpg",
  "/ipor/2.jpg",
  "/ipor/3.jpg",
  "/ipor/4.jpg",
  "/ipor/5.jpg",
  "/ipor/6.jpg",
  "/ipor/7.jpg",
  "/ipor/8.jpg",
  "/ipor/9.jpg",
  "/ipor/10.jpg",
  "/ipor/11.jpg",
  "/ipor/12.jpg",
  "/ipor/13.jpg",
  "/ipor/14.jpg",
  "/ipor/15.jpg",
  "/ipor/16.jpg",
  "/ipor/17.jpg",
  "/ipor/18.jpg",
];

const MakeupPage = () => {

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const toggleSlideBar = () => {
    setIsOpen(!isOpen);
  }; 

  const openModal = (index) => {
    setSelectedImageIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImageIndex(null);
  };

  const goToPreviousImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const goToNextImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  const pages = [
    { name: "עמוד הבית", url: "/" },
    { name: "מי אני", url: "/about" },
    { name: "ימי הולדת 3-6", url: "/BirthdayActivities3to6" },
    { name: "בריתות ואירועים", url: "/BritotEvents" },
    { name: (
      <>
        ימי הולדת בנות
        <br />
        7-10
      </>
    ), 
      url: "/BirthdayParties7to10" },    { name: "איפור פנים לבנות ובנים", url: "/makeup" },
    { name: "בלוני צורות", url: "/shapes-balloons" },
    { name: "גלריית תמונות", url: "/gallery" },
    { name: "הורים ממליצים", url: "/Recommendations" },
    { name: "הופעות ואירועים מהעבר", url: "/PastPerformances" },
  ];

    return (
    <main onMouseDown={handleClickOutside}>     
        <div className="mobile-logo">
  <a href="/" className="home-link">
    <img src="/images/hila-logo.png" alt="לוגו בית" />
  </a>
    </div>
  <div className="mobile-logo1">
  <a href="tel:+972509212108">
    <img src="/images/tel1.png" alt="לוגו האתר" />
  </a>
</div>
          <section>
          <div className="hamburger-menu">
          <button className="hamburger-button" onClick={toggleSlideBar}>
            ☰
          </button>
        </div>
        <div ref={menuRef} className={`side-menu ${isOpen ? 'open' : ''}`}>
          <button className="close-button" onClick={toggleSlideBar}>×</button>
          <ul>
            {pages.map((page, index) => (
              <li key={index}>
                <Link to={page.url} onClick={toggleSlideBar}>{page.name}</Link>
              </li>
            ))}
          </ul>
        </div>
            <h1>איפור פנים (וגוף)</h1>
            <p><strong>העמדת איפור פנים מתאימה כקבלת פנים או תוספת לאירוע קיים (כמו משחקי תחנות, אירוע בג'ימבורי או אירוע מתנפים).</strong></p>
            <p>חשוב לדעת שאני משתמשת באיפור פנים על בסיס מים כך שאפשר להוריד אותו עם מגבונים/מים.</p>
            <p>לכל אירוע כזה אני מביאה איתי את מזוודת האיפור שלי, מפה לשולחן, מראה, מגבונים ותמונות שמתוכם הילדים יכולים לבחור את סוג האיור שהם רוצים.</p>
            <p>
            &#10003; העמדה צבעונית ויפה ומוסיפה תמיד שמחה וכיף לילדים בכל אירוע.<br/>
            &#10003; עמדת איפור פנים תהיה פעילה בין שעה לשעה וחצי (תלוי בכמות הילדים).<br/>
            &#10003; <span style={{ textDecoration: 'underline' }}>אני רק צריכה מכם</span>- שולחן (אפשר קטן) ושני כיסאות - לי ולילד/ה המתאפר/ת.<br/>
            &#10003; ישנה אפשרות לשלב בעמדת איפור פנים גם חלוקת בלוני צורות (בתוספת תשלום).<br/>
            </p>
            <p> <span style={{ textDecoration: 'underline' }}> שימו לב</span> - אם האירוע מתקיים באזור פתוח (בחוץ), העמדה חייבת להיות במקום מוצל מאחר והאיפור הוא על בסיס מים - הוא עלול להיות נמס.</p>
            <p>מוזמנים בעמוד זה לראות תמונות.</p>
            <p><strong>נתראה בשמחות!</strong></p>
            <div className="gallery">
              {images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`תמונה ${index + 1}`}
                  onClick={() => openModal(index)}
                />
              ))}
            </div>
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              className="Modal"
              overlayClassName="Overlay"
            >
              {selectedImageIndex !== null && (
                <div className="modal-content">
                  <div className="modal-buttons">
                    <button onClick={closeModal} className="Modal-close-button">
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  </div>
                  <img src={images[selectedImageIndex]} alt="תמונה מוגדלת" />
                  <div className="modal-buttons">
                    <button onClick={goToPreviousImage} className="modal-button">
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </button>
                    <button onClick={goToNextImage} className="modal-button">
                      <FontAwesomeIcon icon={faArrowRight} />
                    </button>
                  </div>
                </div>
              )}
            </Modal>
          </section>
        </main>
      );
};

export default MakeupPage;
