import React, { useState, useRef } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom'; // Import ה-Link מ-React Router


Modal.setAppElement('#root');

const images = [
  "/BH-36/1.jpg",
  "/BH-36/2.jpg",
  "/BH-36/3.jpg",
  "/BH-36/4.jpg",
  "/BH-36/5.jpg",
  "/BH-36/6.jpg",
  "/BH-36/7.jpg",
  "/BH-36/8.jpg",
  "/BH-36/9.jpg",
  "/BH-36/10.jpg",
  "/BH-36/11.jpg",
  "/BH-36/12.jpg",
  "/BH-36/13.jpg",
  "/BH-36/14.jpg",
  "/BH-36/15.jpg",
  "/BH-36/16.jpg",
  "/BH-36/17.jpg",
  "/BH-36/18.jpg",
  "/BH-36/19.jpg",
  "/BH-36/20.jpg",
  "/BH-36/21.jpg",
  "/BH-36/22.jpg",
];

const BirthdayActivities3to6 = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };


  const toggleSlideBar = () => {
    setIsOpen(!isOpen);
  };
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const openModal = (index) => {
    setSelectedImageIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImageIndex(null);
  };

  const goToPreviousImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const goToNextImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };
  const pages = [
    { name: "עמוד הבית", url: "/" },
    { name: "מי אני", url: "/about" },
    { name: "ימי הולדת 3-6", url: "/BirthdayActivities3to6" },
    { name: "בריתות ואירועים", url: "/BritotEvents" },
    { name: (
      <>
        ימי הולדת בנות
        <br />
        7-10
      </>
    ), 
      url: "/BirthdayParties7to10" },    { name: "איפור פנים לבנות ובנים", url: "/makeup" },
    { name: "בלוני צורות", url: "/shapes-balloons" },
    { name: "גלריית תמונות", url: "/gallery" },
    { name: "הורים ממליצים", url: "/Recommendations" },
    { name: "הופעות ואירועים מהעבר", url: "/PastPerformances" },
  ];
  return (
    <main onMouseDown={handleClickOutside}>     
        <div className="mobile-logo">
  <a href="/" className="home-link">
    <img src="/images/hila-logo.png" alt="לוגו בית" />
  </a>
    </div>
  <div className="mobile-logo1">
  <a href="tel:+972509212108">
    <img src="/images/tel1.png" alt="לוגו האתר" />
  </a>
</div>
      <section>
      <div className="hamburger-menu">
          <button className="hamburger-button" onClick={toggleSlideBar}>
            ☰
          </button>
        </div>
        <div ref={menuRef} className={`side-menu ${isOpen ? 'open' : ''}`}>
          <button className="close-button" onClick={toggleSlideBar}>×</button>
          <ul>
            {pages.map((page, index) => (
              <li key={index}>
                <Link to={page.url} onClick={toggleSlideBar}>{page.name}</Link>
              </li>
            ))}
          </ul>
        </div>
        <h1><span class="line">יום הולדת חוויתית</span><span class="line">גילאי 3-6</span></h1>
        <h2><strong>מזל טוב, הילד/ה שלכם גדל/ה בשנה - זה הזמן למסיבה מדליקה.</strong></h2>
        <p>כל הילדים מחכים בקוצר רוח ליום הולדתם, ובצדק - זהו יום שכולו רק שלהם!
        לכן, המטרה שלי ביום הולדת, זה בראש ובראשונה "ילדי היום הולדת" - הם יהיו "הכוכבים של המסיבה".</p>
        <p>כמו כן, חשוב לא לשכוח את שאר הילדים שהגיעו במיוחד לחגוג איתנו, לכן בהפעלה שלי, כולם שותפים ופעילים לאורך כל המסיבה ובדגש תמיד על ילד/ת היום הולדת.</p>  
        <p><strong>החגיגה מורכבת ממגוון פעילויות:</strong></p>
        <p>
        &#10003; קבלת פנים קצרצרה עם עמדת איפור ומוסיקה מקפיצה.<br/>
        &#10003; מופע קצרצר לכוכב/ת היום הולדת עם תולי החתול שלי, בלונים וצחקוקים.<br/>
        &#10003; הרקדה מקפיצה לכל הילדים.<br/>
        &#10003; הפעלת משחקים מהנים לכולם כגון לימבו, מסלול אתגרים, מצנח עוד.<br/>
        &#10003; טקס עוגה - כיבוי נרות, ברכות החברים והרמת כוכב/ת היום הולדת.<br/>
        &#10003; חלוקת פרסים ובלוני צורות בצבעים מדליקים.<br/>
        </p>
        <p>אז יהיה שמח, מגוון ומהנה.</p>
        <p>אני דואגת להביא איתי מחצלת (לילדים), בידורית ומיקרופון (הגברה), מוסיקה, אביזרים וגודש חיוכים לכל המשתתפים.</p>
        <p>
        <li>יום הולדת לגילאי 3 הפעלה עד שעה וחצי (מאחר והילדים קטנטנים מאוד).</li>
        <li>יום הולדת לגילאי 4-6 שעתיים כולל הפסקה.</li>
        </p>
        <p><strong>אז יום הולדת שמח</strong> ♥</p>

                        <div className='video-container1'>

<iframe
width="230"
height="170"
src="https://www.youtube.com/embed/UM_vNRigzGU?si=Q8lbP5cMqdOnGCWr"
frameBorder="0"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
title="Video 1"
></iframe>
</div>
<div className="gallery">
{images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`תמונה ${index + 1}`}
              onClick={() => openModal(index)}
            />
          ))}
       
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className="Modal"
          overlayClassName="Overlay"
        >
          {selectedImageIndex !== null && (
            <div className="modal-content">
              <div className="modal-buttons">
                <button onClick={closeModal} className="Modal-close-button">
                <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              <img src={images[selectedImageIndex]} alt="תמונה מוגדלת" />
              <div className="modal-buttons">
                <button onClick={goToPreviousImage} className="modal-button">
                  <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <button onClick={goToNextImage} className="modal-button">
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </div>
            </div>
          )}
        </Modal>
      </section>
    </main>
  );
};

export default BirthdayActivities3to6;
