import React, { useState, useRef } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom'; // Import ה-Link מ-React Router


Modal.setAppElement('#root');

const images = [
  "/BH-36/1.jpg",
  "/BH-36/2.jpg",
  "/BH-36/3.jpg",
  "/BH-36/4.jpg",
  "/BH-36/5.jpg",
  "/BH-36/6.jpg",
  "/BH-36/7.jpg",
  "/BH-36/8.jpg",
  "/BH-36/9.jpg",
  "/BH-36/10.jpg",
  "/BH-36/11.jpg",
  "/BH-36/12.jpg",
  "/BH-36/13.jpg",
  "/BH-36/14.jpg",
  "/BH-36/15.jpg",
  "/BH-36/16.jpg",
  "/BH-36/17.jpg",
  "/BH-36/18.jpg",
  "/BH-36/19.jpg",
  "/BH-36/20.jpg",
  "/BH-36/21.jpg",
  "/BH-36/22.jpg",
  "/brit/1.jpg",
  "/brit/2.jpg",
  "/brit/3.jpg",
  "/brit/4.jpg",
  "/brit/6.jpg",
  "/brit/7.jpg",
  "/brit/8.jpg",
  "/brit/9.jpg",
  "/brit/10.jpg",
  "/brit/11.jpg",
  "/brit/12.jpg",
  "/brit/13.jpg",
  "/brit/14.jpg",
  "/brit/15.jpg",
  "/BH-710/1.jpg",
  "/BH-710/2.jpg",
  "/BH-710/3.jpg",
  "/BH-710/4.jpg",
  "/BH-710/5.jpg",
  "/BH-710/6.jpg",
  "/BH-710/7.jpg",
  "/BH-710/8.jpg",
  "/BH-710/9.jpg",
  "/BH-710/10.jpg",
  "/BH-710/11.jpg",
  "/BH-710/12.jpg",
  "/ipor/1.jpg",
  "/ipor/2.jpg",
  "/ipor/3.jpg",
  "/ipor/4.jpg",
  "/ipor/5.jpg",
  "/ipor/6.jpg",
  "/ipor/7.jpg",
  "/ipor/8.jpg",
  "/ipor/9.jpg",
  "/ipor/10.jpg",
  "/ipor/11.jpg",
  "/ipor/12.jpg",
  "/ipor/13.jpg",
  "/ipor/14.jpg",
  "/ipor/15.jpg",
  "/ipor/16.jpg",
  "/ipor/17.jpg",
  "/ipor/18.jpg",
  "/balon/1.jpg",
  "/balon/2.jpg",
  "/balon/3.jpg",
  "/balon/6.jpg",
  "/balon/7.jpg",
  "/balon/8.jpg",
  "/balon/9.jpg",
  "/balon/10.jpg",
  "/balon/11.jpg",
  "/balon/12.jpg",
  "/balon/4.jpg",
  "/balon/5.jpg",
  "/shows/1.jpg",
  "/shows/2.jpg",
  "/shows/3.jpg",
  "/shows/4.jpg",
  "/shows/5.jpg",
  "/shows/6.jpg",
  "/shows/7.jpg",
  "/shows/8.jpg",
  "/shows/9.jpg",
  "/shows/10.jpg",
  "/shows/11.jpg",
  "/shows/12.jpg",
  "/shows/13.jpg",
  "/shows/14.jpg",
  "/shows/15.jpg",
  "/shows/16.jpg",
  "/shows/17.jpg",
  "/shows/18.jpg",
  "/shows/19.jpg",
  "/shows/20.jpg",
  "/shows/21.jpg",
  "/shows/22.jpg",
  "/shows/23.jpg",
  "/shows/24.jpg",
];

const GalleryPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const menuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const toggleSlideBar = () => {
    setIsOpen(!isOpen);
  };

  const openModal = (index) => {
    setSelectedImageIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImageIndex(null);
  };

  const goToPreviousImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const goToNextImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  const pages = [
    { name: "עמוד הבית", url: "/" },
    { name: "מי אני", url: "/about" },
    { name: "ימי הולדת 3-6", url: "/BirthdayActivities3to6" },
    { name: "בריתות ואירועים", url: "/BritotEvents" },
    { name: (
      <>
        ימי הולדת בנות
        <br />
        7-10
      </>
    ), 
      url: "/BirthdayParties7to10" },    { name: "איפור פנים לבנות ובנים", url: "/makeup" },
    { name: "בלוני צורות", url: "/shapes-balloons" },
    { name: "גלריית תמונות", url: "/gallery" },
    { name: "הורים ממליצים", url: "/Recommendations" },
    { name: "הופעות ואירועים מהעבר", url: "/PastPerformances" },
  ];
  return (
    <main onMouseDown={handleClickOutside}>    
        <div className="mobile-logo">
  <a href="/" className="home-link">
    <img src="/images/hila-logo.png" alt="לוגו בית" />
  </a>
    </div>
  <div className="mobile-logo1">
  <a href="tel:+972509212108">
    <img src="/images/tel1.png" alt="לוגו האתר" />
  </a>
</div> 
        <section>
        <div className="hamburger-menu">
          <button className="hamburger-button" onClick={toggleSlideBar}>
            ☰
          </button>
        </div>
        <div ref={menuRef} className={`side-menu ${isOpen ? 'open' : ''}`}>
          <button className="close-button" onClick={toggleSlideBar}>×</button>
          <ul>
            {pages.map((page, index) => (
              <li key={index}>
                <Link to={page.url} onClick={toggleSlideBar}>{page.name}</Link>
              </li>
            ))}
          </ul>
        </div>
          <h1>גלריית תמונות</h1>
          <h2>כאן תוכלו לראות בגלריה אחת את כל התמונות מהאתר.</h2>
          <div className="gallery">
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`תמונה ${index + 1}`}
                onClick={() => openModal(index)}
              />
            ))}
          </div>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            className="Modal"
            overlayClassName="Overlay"
          >
            {selectedImageIndex !== null && (
              <div className="modal-content">
                <div className="modal-buttons">
                  <button onClick={closeModal} className="Modal-close-button">
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>
                <img src={images[selectedImageIndex]} alt="תמונה מוגדלת" className="modal-image" />
                <div className="modal-buttons">
                  <button onClick={goToPreviousImage} className="modal-button">
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </button>
                  <button onClick={goToNextImage} className="modal-button">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </button>
                </div>
              </div>
            )}
          </Modal>
        </section>
    </main>
  );
};

export default GalleryPage;
