// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Gallery from './pages/Gallery';
import MakeupPage from './pages/MakeupPage'; 
import ShapesBalloons from './pages/ShapesBalloons'; 
import BirthdayActivities3to6 from './pages/BirthdayActivities3to6';
import BritotEvents from './pages/BritotEvents';
import BirthdayParties7to10 from './pages/BirthdayParties7to10';
import Recommendations from './pages/Recommendations';
import PastPerformances from './pages/PastPerformances';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './App.css';



const App = () => {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/makeup" element={<MakeupPage />} />
          <Route path="/shapes-balloons" element={<ShapesBalloons />} />
          <Route path="/BirthdayActivities3to6" element={<BirthdayActivities3to6 />} />
          <Route path="/BritotEvents" element={<BritotEvents />} />
          <Route path="/BirthdayParties7to10" element={<BirthdayParties7to10 />} />
          <Route path="/Recommendations" element={<Recommendations />} />
          <Route path="/PastPerformances" element={<PastPerformances />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
