import React, { useState, useRef } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom'; // Import ה-Link מ-React Router


Modal.setAppElement('#root');

const images = [
  "/shows/1.jpg",
  "/shows/2.jpg",
  "/shows/3.jpg",
  "/shows/4.jpg",
  "/shows/5.jpg",
  "/shows/6.jpg",
  "/shows/7.jpg",
  "/shows/8.jpg",
  "/shows/9.jpg",
  "/shows/10.jpg",
  "/shows/11.jpg",
  "/shows/12.jpg",
  "/shows/13.jpg",
  "/shows/14.jpg",
  "/shows/15.jpg",
  "/shows/16.jpg",
  "/shows/17.jpg",
  "/shows/18.jpg",
  "/shows/19.jpg",
  "/shows/20.jpg",
  "/shows/21.jpg",
  "/shows/22.jpg",
  "/shows/23.jpg",
  "/shows/24.jpg",
];

const PastPerformances = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const toggleSlideBar = () => {
    setIsOpen(!isOpen);
  };

  const openModal = (index) => {
    setSelectedImageIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImageIndex(null);
  };

  const goToPreviousImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const goToNextImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  const pages = [
    { name: "עמוד הבית", url: "/" },
    { name: "מי אני", url: "/about" },
    { name: "ימי הולדת 3-6", url: "/BirthdayActivities3to6" },
    { name: "בריתות ואירועים", url: "/BritotEvents" },
    { name: (
      <>
        ימי הולדת בנות
        <br />
        7-10
      </>
    ), 
      url: "/BirthdayParties7to10" },    { name: "איפור פנים לבנות ובנים", url: "/makeup" },
    { name: "בלוני צורות", url: "/shapes-balloons" },
    { name: "גלריית תמונות", url: "/gallery" },
    { name: "הורים ממליצים", url: "/Recommendations" },
    { name: "הופעות ואירועים מהעבר", url: "/PastPerformances" },
  ];

  return (
    <main onMouseDown={handleClickOutside}>     
        <div className="mobile-logo">
  <a href="/" className="home-link">
    <img src="/images/hila-logo.png" alt="לוגו בית" />
  </a>
    </div>
  <div className="mobile-logo1">
  <a href="tel:+972509212108">
    <img src="/images/tel1.png" alt="לוגו האתר" />
  </a>
</div>
      <section>
      <div className="hamburger-menu">
          <button className="hamburger-button" onClick={toggleSlideBar}>
            ☰
          </button>
        </div>
        <div ref={menuRef} className={`side-menu ${isOpen ? 'open' : ''}`}>
          <button className="close-button" onClick={toggleSlideBar}>×</button>
          <ul>
            {pages.map((page, index) => (
              <li key={index}>
                <Link to={page.url} onClick={toggleSlideBar}>{page.name}</Link>
              </li>
            ))}
          </ul>
        </div>
        <h1>אירועים והופעות מהעבר</h1>
        <p>הורים ומבקרים יקרים, אני במקצוע הבמה וההפעלות כמעט שני עשורים, במהלך חיי באמת עשיתי המון דברים בתחום הבמה.</p>
        <p>אז למי שרוצה להכיר אותי יותר או למי שזוכר אותי מהופעות (פאזל עם הילה ולירן ויומנה של הילה) ואהב את המוסיקה, יכול לצפות ולהאזין כאן מתוך שירים וקטעים שלי מיוטיוב.</p>
        <div className="gallery">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`תמונה ${index + 1}`}
              onClick={() => openModal(index)}
            />
          ))}

              <div className='video-container'>
            <iframe
            width="230"
            height="170"
            src="https://www.youtube.com/embed/nN9WHR8lMag?si=tQPFbxZ7On6lfrMC"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Video 1"
            ></iframe>
            <iframe
            width="230"
            height="170"
            src="https://www.youtube.com/embed/43Sdw2XkX-g?si=Flm5lxoUAD_cBbSD"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Video 2"
            ></iframe>
                      <iframe
            width="230"
            height="170"
            src="https://www.youtube.com/embed/ujNw5OMnR0w?si=ZiSpcmOH2HmuYkAd"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Video 3"
            ></iframe>
      <iframe
            width="230"
            height="170"
            src="https://www.youtube.com/embed/UeACL0l47GM?si=RqDFtDY0wnPY6Ugm"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Video 4"
            ></iframe>
                <iframe
            width="230"
            height="170"
            src="https://www.youtube.com/embed/-xBpbZGjun4?si=yMw5LsaT6C_nZA4B"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Video 5"
            ></iframe>
         <iframe
            width="230"
            height="170"
            src="https://www.youtube.com/embed/FNnZkAeY61g?si=q0Bih0tZbHUpdEU3"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Video 6"
            ></iframe>
                   <iframe
            width="230"
            height="170"
            src="https://www.youtube.com/embed/eCy7cNk3rKg?si=316yDRQ_7xtKWUjZ"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Video 7"
            ></iframe>
            </div>
            </div>
            <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className="Modal"
          overlayClassName="Overlay"
        >
          {selectedImageIndex !== null && (
            <div className="modal-content">
              <div className="modal-buttons">
                <button onClick={closeModal} className="Modal-close-button">
                <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              <img src={images[selectedImageIndex]} alt="תמונה מוגדלת" />
              <div className="modal-buttons">
                <button onClick={goToPreviousImage} className="modal-button">
                  <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <button onClick={goToNextImage} className="modal-button">
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </div>
            </div>
          )}
        </Modal>
      </section>
    </main>
  );
};

export default PastPerformances;
