import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom'; // Import ה-Link מ-React Router
import '../App.css'; // או כל קובץ CSS אחר

const About = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleSlideBar = () => {
    setIsOpen(!isOpen);
  };
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const pages = [
    { name: "עמוד הבית", url: "/" },
    { name: "מי אני", url: "/about" },
    { name: "ימי הולדת 3-6", url: "/BirthdayActivities3to6" },
    { name: "בריתות ואירועים", url: "/BritotEvents" },
    { name: (
      <>
        ימי הולדת בנות
        <br />
        7-10
      </>
    ), 
      url: "/BirthdayParties7to10" },    { name: "איפור פנים לבנות ובנים", url: "/makeup" },
    { name: "בלוני צורות", url: "/shapes-balloons" },
    { name: "גלריית תמונות", url: "/gallery" },
    { name: "הורים ממליצים", url: "/Recommendations" },
    { name: "הופעות ואירועים מהעבר", url: "/PastPerformances" },
  ];

  return (
    <main onMouseDown={handleClickOutside}>     
    <div className="mobile-logo">
  <a href="/" className="home-link">
    <img src="/images/hila-logo.png" alt="לוגו בית" />
  </a>
    </div>
  <div className="mobile-logo1">
  <a href="tel:+972509212108">
    <img src="/images/tel1.png" alt="לוגו האתר" />
  </a>
</div>
     <section>
        <div className="hamburger-menu">
          
          <button className="hamburger-button" onClick={toggleSlideBar}>
            ☰
          </button>
        </div>
       
        <div ref={menuRef} className={`side-menu ${isOpen ? 'open' : ''}`}>
          <button className="close-button" onClick={toggleSlideBar}>×</button>
          <ul>
            {pages.map((page, index) => (
              <li key={index}>
                <Link to={page.url} onClick={toggleSlideBar}>{page.name}</Link>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <h1>מי אני?</h1>
          <p><strong>הורים ומבקרים יקרים שלום ונעים מאוד, שמי הילה.</strong></p>
          <p>ראשית כל, כיום אני אמא לשלושה ילדים מתוקים וכמעט שני עשורים נמצאת בתחום הפעלות לילדים.</p>
          <p>התחלתי את דרכי בצוות בידור באילת במלונות ישרוטל ומשם הכל אגדה.</p>
          <p>המשכתי וסיימתי לימודי משחק בבי"ס לאמנויות הבמה "בית צבי" ברמת גן.</p>
          <p>עם סיום הלימודים, פתחתי עסק להפעלות ילדים, הופעתי בהצגות, מופעים בקניונים, באירועי חוצות, תיאטרון רחוב ואירועים פרטיים.</p>
          <p>אחרי כמה שנים, למדתי וסיימתי תואר ראשון בהצטיינות יתרה - "חינוך לגיל הרך".</p>
          <p>כיום, בשל היותי אמא ועובדת במשרה קבועה ויציבה, הפחתתי את תחום הבמה ואני מתמקדת בעיקר בהפעלות ילדים באזורי השרון, המרכז, השפלה וירושלים.</p>
          <p>אם יש לכם אירוע באזור המדובר, אשמח מאוד לשוחח איתכם ולהציע את שירותיי כמפעילת האירוע לילדים שלכם.</p>
          <p>אתם מוזמנים לקרוא מה אני עושה בהפעלות שלי, לראות תמונות והמלצות.</p>
          <p><strong>שנתראה תמיד רק בשמחות.</strong></p>
          <p>באהבה ♥ הילה.</p>
        </div>
        <div className="profile-image">
          <img src="/images/IAM.jpg" alt="תמונת פרופיל" />
        </div>
      </section>
    </main>
  );
};

export default About;
