import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import '../Contact.css';
import { Link } from 'react-router-dom';

const Home = () => {
  useEffect(() => {
    const animateScreen = () => {
      document.querySelector('.home').classList.add('animate__animated', 'animate__zoomIn');
    };

    animateScreen();
  }, []);

  const telepon = 'tel:+972509212108';
  const email = 'mailto:hilahezi86@gmail.com';
  const message = 'שלום%20הילה%2C%20אני%20מעוניין%2Fת%20בקבלת%20פרטים%20על%20הפעלה...'; // הכנס את מספר הטלפון שלך בפורמט בינלאומי
  const whatsapp = `https://wa.me/972509212108?text=${message}`;
  // const handleDropdownChange = (event) => {
  //   window.location.href = event.target.value;
  // };

  return (
    <div className='home animate__animated animate__zoomIn'> 
        <div className="mobile-logo">
  <a href="/" className="home-link">
    <img src="/images/hila-logo.png" alt="לוגו בית" />
  </a>
    </div>
  <div className="mobile-logo1">
  <a href="tel:+972509212108">
    <img src="/images/tel1.png" alt="לוגו האתר" />
  </a>
</div>
      <div className="contact-icons2">
        <a href={telepon} className="contact-icon">
          <FontAwesomeIcon icon={faPhone} size="3x"/>
        </a>

        <a href={email} className="contact-icon">
          <FontAwesomeIcon icon={faEnvelope} size="3x" />
        </a>
        <a href={whatsapp} target="_blank" rel="noopener noreferrer" className="contact-icon">
          <FontAwesomeIcon icon={faWhatsapp} size="3x" />
        </a>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
        <ul className="nav-links1">
          <li className="logo">
            <Link to="/"style={{ display: 'flex', marginRight:'10%' }}>
              <img src="/images/logoo.png" alt="לוגו" style={{ maxWidth: '40%', height: 'auto' }} />
            </Link>
          </li>
        </ul>

        <ul className="nav-links2">
          <li className="pic2">
            <Link to="/about">
              <img src="/images/about-icon.png" alt="מי אני" style={{ maxWidth: '180%', height: 'auto', marginRight: '110%' }} />
            </Link>
          </li>
        </ul>
      </div>

      <div>
        <ul className="nav-linkss">
        <li className="pic">
            <Link to="/about">
              <img src="/images/about-icon.png" alt="מי אני" />
            </Link>
          </li>
          <li className="pic">
            <Link to="/BirthdayActivities3to6">
              <img src="/images/HB.png" alt="ימי הולדת 3-6" />
            </Link>
          </li>
          <li className="pic">
            <Link to="/BritotEvents">
              <img src="/images/brit.png" alt="בריתות ואירועים" />
            </Link>
          </li>
          <li className="pic">
            <Link to="/BirthdayParties7to10">
              <img src="/images/bd-710.png" alt="ימי הולדת 7-10" />
            </Link>
          </li>
          <li className="pic">
            <Link to="/makeup">
              <img src="/images/ipor.png" alt="איפור פנים" />
            </Link>
          </li>
          <li className="pic">
            <Link to="/shapes-balloons">
              <img src="/images/balon-icon.png" alt="בלוני צורות" />
            </Link>
          </li>
          <li className="pic">
            <Link to="/gallery">
              <img src="/images/images-icon.png" alt="תמונות" />
            </Link>
          </li>
          <li className="pic">
            <Link to="/Recommendations">
              <img src="/images/aml.png" alt="המלצות" />
            </Link>
          </li>
          <li className="pic">
            <Link to="/PastPerformances">
              <img src="/images/show-past.png" alt="אירועים מהעבר" />
            </Link>
          </li>
        </ul>

        {/* <select className="nav-dropdown" onChange={handleDropdownChange}>
          <option value="#" selected disabled>הילה-קידס</option>
          <option value="/PastPerformances">אירועים מהעבר</option>
          <option value="/Recommendations">המלצות</option>
          <option value="/gallery">תמונות</option>
          <option value="/shapes-balloons">בלוני צורות</option>
          <option value="/makeup">איפור פנים</option>
          <option value="/BirthdayParties7to10">ימי הולדת 7-10</option>
          <option value="/BritotEvents">בריתות ואירועים</option>
          <option value="/BirthdayActivities3to6">ימי הולדת 3-6</option>
        </select> */}
      </div>
    </div>
  );
};

export default Home;
