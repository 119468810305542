import React, { useState, useRef } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom'; // Import ה-Link מ-React Router


Modal.setAppElement('#root');

const images = [
  "/recom/1.jpg",
  "/recom/2.jpg",
  "/recom/3.jpg",
  "/recom/4.jpg",
  "/recom/5.jpg",
  "/recom/6.png",
  "/recom/7.jpg",
  "/recom/8.jpg",
  "/recom/9.jpg",
  "/recom/10.jpg",
  "/recom/11.jpg",
  "/recom/12.jpg",
  "/recom/13.jpg",
  "/recom/14.jpg",
  "/recom/15.jpg",
  "/recom/16.jpg",
  "/recom/17.jpg",
  "/recom/18.png",
  "/recom/19.jpg",
  "/recom/20.jpg",
  "/recom/21.png",
];


const Recommendations = () => {
    
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const toggleSlideBar = () => {
    setIsOpen(!isOpen);
  };

  const openModal = (index) => {
    setSelectedImageIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImageIndex(null);
  };

  const goToPreviousImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const goToNextImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  const pages = [
    { name: "עמוד הבית", url: "/" },
    { name: "מי אני", url: "/about" },
    { name: "ימי הולדת 3-6", url: "/BirthdayActivities3to6" },
    { name: "בריתות ואירועים", url: "/BritotEvents" },
    { name: (
      <>
        ימי הולדת בנות
        <br />
        7-10
      </>
    ), 
      url: "/BirthdayParties7to10" },    { name: "איפור פנים לבנות ובנים", url: "/makeup" },
    { name: "בלוני צורות", url: "/shapes-balloons" },
    { name: "גלריית תמונות", url: "/gallery" },
    { name: "הורים ממליצים", url: "/Recommendations" },
    { name: "הופעות ואירועים מהעבר", url: "/PastPerformances" },
  ];

  return (
    <main onMouseDown={handleClickOutside}>     
        <div className="mobile-logo">
  <a href="/" className="home-link">
    <img src="/images/hila-logo.png" alt="לוגו בית" />
  </a>
    </div>
  <div className="mobile-logo1">
  <a href="tel:+972509212108">
    <img src="/images/tel1.png" alt="לוגו האתר" />
  </a>
</div>
      <section>
      <div className="hamburger-menu">
          <button className="hamburger-button" onClick={toggleSlideBar}>
            ☰
          </button>
        </div>
        <div ref={menuRef} className={`side-menu ${isOpen ? 'open' : ''}`}>
          <button className="close-button" onClick={toggleSlideBar}>×</button>
          <ul>
            {pages.map((page, index) => (
              <li key={index}>
                <Link to={page.url} onClick={toggleSlideBar}>{page.name}</Link>
              </li>
            ))}
          </ul>
        </div>
        <h1>הורים ממליצים</h1>
        <p>הורים יקרים שלום, אני מאמינה <span style={{ textDecoration: 'underline' }}> שאין יותר טוב מחוות דעת של אחרים</span>, לכן קיים עמוד זה, כאן תוכלו לקרוא תגובות של הורים שהזמינו אותי להפעלות או אירועים שלהם.</p>
        <p>חשוב לי לכתוב תודה רבה לכל מי שכותב לי, תמיד מרגש אותי לקבל תודות ומילים חמות מכם (גם אחרי שנים במקצוע), אז תודה שאתם גורמים לי לחייך ולהמשיך לעשות את הכי טוב כדי שיהיה לילדים שלכם הכי טוב!</p>
        <p><strong>תודה רבה!</strong></p>
        <p><strong>הילה ♥</strong></p>
    <div className="gallery">
    {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`תמונה ${index + 1}`}
              onClick={() => openModal(index)}
            />
          ))}
      
            </div>
            <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className="Modal"
          overlayClassName="Overlay"
        >
          {selectedImageIndex !== null && (
            <div className="modal-content">
              <div className="modal-buttons">
                <button onClick={closeModal} className="Modal-close-button">
                <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              <img src={images[selectedImageIndex]} alt="תמונה מוגדלת" />
              <div className="modal-buttons">
                <button onClick={goToPreviousImage} className="modal-button">
                  <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <button onClick={goToNextImage} className="modal-button">
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </div>
            </div>
          )}
        </Modal>
      </section>
    </main>
  );
};

export default Recommendations;
