import React, { useState, useRef } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom'; // Import ה-Link מ-React Router


Modal.setAppElement('#root');

const images = [
  "/balon/1.jpg",
  "/balon/2.jpg",
  "/balon/3.jpg",
  "/balon/6.jpg",
  "/balon/7.jpg",
  "/balon/8.jpg",
  "/balon/9.jpg",
  "/balon/10.jpg",
  "/balon/11.jpg",
  "/balon/12.jpg",
  "/balon/4.jpg",
  "/balon/5.jpg",
];

const ShapesBalloons = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const toggleSlideBar = () => {
    setIsOpen(!isOpen);
  };

  const openModal = (index) => {
    setSelectedImageIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImageIndex(null);
  };

  const goToPreviousImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const goToNextImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };
  const pages = [
    { name: "עמוד הבית", url: "/" },
    { name: "מי אני", url: "/about" },
    { name: "ימי הולדת 3-6", url: "/BirthdayActivities3to6" },
    { name: "בריתות ואירועים", url: "/BritotEvents" },
    { name: (
      <>
        ימי הולדת בנות
        <br />
        7-10
      </>
    ), 
      url: "/BirthdayParties7to10" },
    { name: "איפור פנים לבנות ובנים", url: "/makeup" },
    { name: "בלוני צורות", url: "/shapes-balloons" },
    { name: "גלריית תמונות", url: "/gallery" },
    { name: "הורים ממליצים", url: "/Recommendations" },
    { name: "הופעות ואירועים מהעבר", url: "/PastPerformances" },
  ];
  return (
    <main onMouseDown={handleClickOutside}>     
        <div className="mobile-logo">
  <a href="/" className="home-link">
    <img src="/images/hila-logo.png" alt="לוגו בית" />
  </a>
    </div>
  <div className="mobile-logo1">
  <a href="tel:+972509212108">
    <img src="/images/tel1.png" alt="לוגו האתר" />
  </a>
</div>
      <section>
      <div className="hamburger-menu">
          <button className="hamburger-button" onClick={toggleSlideBar}>
            ☰
          </button>
        </div>
        <div ref={menuRef} className={`side-menu ${isOpen ? 'open' : ''}`}>
          <button className="close-button" onClick={toggleSlideBar}>×</button>
          <ul>
            {pages.map((page, index) => (
              <li key={index}>
                <Link to={page.url} onClick={toggleSlideBar}>{page.name}</Link>
              </li>
            ))}
          </ul>
        </div>
        <h1>בלוני צורות</h1>
        <h2>בלונים ארוכים צבעוניים ובצורות מגוונות, משמחים ונותנים המון צבע בכל אירוע! יחד עם זאת - חלוקת בלוני צורות הינה תוספת לאירוע קיים.</h2>
        <p>באירוע שכזה, אני מביאה איתי תיק עם בלונים ארוכים ומשאבה.</p>
        <p>אני מכינה צורות במקום לבקשת הילדים.</p>
        <p>בין הצורות שאני מכינה: חרבות, כתרים, בעלי חיים, צמידים, פרחים לבבות ועוד.</p>
        <p>
          &#10003; ילדים מאוד מתחברים לבלונים, והבלונים תמיד מוסיפים צבע ושמחה לאירוע.<br/>
          &#10003; חלוקת בלוני צורות תהיה פעילה בין שעה לשעה וחצי (תלוי בכמות הילדים).<br/>
          &#10003; ישנה אפשרות להזמין אותי לסדנת בלוני צורות - בסדנה זו, אני מלמדת איך מכינים צורות, אני מחלקת לכל משתתף בלונים ויחד אנו יוצרים דמות.<br/>
        </p>
        <p>כאן תוכלו לראות תמונות של צורות בלונים שני מכינה באהבה ושמחה.</p>
        <p><strong>נתראה בשמחות!</strong></p>
        <div className="gallery">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`תמונה ${index + 1}`}
              className={index >= images.length - 2 ? 'last-two' : ''}
              onClick={() => openModal(index)}
            />
          ))}
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className="Modal"
          overlayClassName="Overlay"
        >
          {selectedImageIndex !== null && (
            <div className="modal-content">
              <div className="modal-buttons">
                <button onClick={closeModal} className="Modal-close-button">
                <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              <img src={images[selectedImageIndex]} alt="תמונה מוגדלת" />
              <div className="modal-buttons">
                <button onClick={goToPreviousImage} className="modal-button">
                  <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <button onClick={goToNextImage} className="modal-button">
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </div>
            </div>
          )}
        </Modal>
      </section>
    </main>
  );
};

export default ShapesBalloons;
