import React, { useState, useRef } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom'; // Import ה-Link מ-React Router


Modal.setAppElement('#root');

const images = [
  "/BH-710/1.jpg",
  "/BH-710/2.jpg",
  "/BH-710/3.jpg",
  "/BH-710/4.jpg",
  "/BH-710/5.jpg",
  "/BH-710/6.jpg",
  "/BH-710/7.jpg",
  "/BH-710/8.jpg",
  "/BH-710/9.jpg",
  "/BH-710/10.jpg",
  "/BH-710/11.jpg",
  "/BH-710/12.jpg",
];


const BirthdayParties7to10 = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const toggleSlideBar = () => {
    setIsOpen(!isOpen);
  };


  const openModal = (index) => {
    setSelectedImageIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImageIndex(null);
  };

  const goToPreviousImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const goToNextImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };
  const pages = [
    { name: "עמוד הבית", url: "/" },
    { name: "מי אני", url: "/about" },
    { name: "ימי הולדת 3-6", url: "/BirthdayActivities3to6" },
    { name: "בריתות ואירועים", url: "/BritotEvents" },
    { name: (
      <>
        ימי הולדת בנות
        <br />
        7-10
      </>
    ), 
      url: "/BirthdayParties7to10" },    { name: "איפור פנים לבנות ובנים", url: "/makeup" },
    { name: "בלוני צורות", url: "/shapes-balloons" },
    { name: "גלריית תמונות", url: "/gallery" },
    { name: "הורים ממליצים", url: "/Recommendations" },
    { name: "הופעות ואירועים מהעבר", url: "/PastPerformances" },
  ];
  return (

    <main onMouseDown={handleClickOutside}>     

<section>
<div className="hamburger-menu">
<div className="mobile-logo">
  <a href="/" className="home-link">
    <img src="/images/hila-logo.png" alt="לוגו בית" />
  </a>
    </div>
  <div className="mobile-logo1">
  <a href="tel:+972509212108">
    <img src="/images/tel1.png" alt="לוגו האתר" />
  </a>
</div>
          <button className="hamburger-button" onClick={toggleSlideBar}>
            ☰
          </button>
        </div>
        <div ref={menuRef} className={`side-menu ${isOpen ? 'open' : ''}`}>
          <button className="close-button" onClick={toggleSlideBar}>×</button>
          <ul>
            {pages.map((page, index) => (
              <li key={index}>
                <Link to={page.url} onClick={toggleSlideBar}>{page.name}</Link>
              </li>
            ))}
          </ul>
        </div>
        <h1><span class="line">יום הולדת- בנות</span><span class="line">מתאים לגילאי 7-10</span></h1>
      <h2>מזל טוב, הילדה שלכם כבר בוגרת - זה הזמן למסיבת בנות כיפית עם החברות הטובות</h2>
      <p> באירוע כזה חשוב שכוכבת היום הולדת תרגיש במרכז והאירוע יהיה חוויתי ובלתי נשכח לכלל
      החברות. </p>
      <h2>אז כיצד נחגוג יום הולדת בנות:</h2>
      <p>
      &#10003; אתחיל עם איפור פנים או קעקועים ומוסיקת רקע.<br/>
      &#10003; משחק שאלון לחברות על כוכבת היום הולדת.<br/>
      &#10003; משחקים מגבשים וכפיים כגון, לימבו, קפיצות, תחנות, מסלול מאתגר ועוד.<br/>
      &#10003; מסיבת דיסקו מוסיקלית מקפיצה וכיפית.(אופציונאלי).<br/>
      &#10003; טקס קבלת עוגה וברכות לכוכבת המסיבה.<br/>
      &#10003; חלוקת פרסים ובלוני צורות בשלל עיצובים - צמידים, בעלי חיים, לבבות ועוד.<br/>
      </p>
      <p>•	לילדות גדולות בגילאי 9-10 יש אופציה לסדנת למידת בלוני צורות - כל ילדה תקבל בלונים, וביחד נלמד להכין כלבלב מחובר לכתר על הראש.</p>
      <p>•	חשוב לדעת שאני מתאימה את עצמי לסוג האירוע ולילדים, הכל יכול מעט להשתנות לטובת הצלחת האירוע והנאת הילדים.</p>

      <p><strong>יהיה מעניין, חוויתי ובילתי נשכח.</strong></p>

      <div className="gallery">
      {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`תמונה ${index + 1}`}
              onClick={() => openModal(index)}
            />
          ))}        
            </div>
            <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className="Modal"
          overlayClassName="Overlay"
        >
          {selectedImageIndex !== null && (
            <div className="modal-content">
              <div className="modal-buttons">
                <button onClick={closeModal} className="Modal-close-button">
                <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              <img src={images[selectedImageIndex]} alt="תמונה מוגדלת" />
              <div className="modal-buttons">
                <button onClick={goToPreviousImage} className="modal-button">
                  <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <button onClick={goToNextImage} className="modal-button">
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </div>
            </div>
          )}
        </Modal>

</section>
        </main>

  );
};

export default BirthdayParties7to10;
